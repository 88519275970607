import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"
import "./style.scss"
import ContentNewsPage from "../../domain/news"

const index = () => {
  return (
    <div className="product-request">
      <Topbar />
      <CustomLayout selectedPage={<ContentNewsPage />} />
    </div>
  )
}

export default index
